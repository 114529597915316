// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'
import apiPublic from '../api/apiPublic'

// Shared
import { getLocalStorage, setLocalStorage } from '../ruya-shared/shared/utils/storageHelper'
import { apiErrorHandler } from '../ruya-shared/shared/utils/errorHelper'
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Initial State
const initialState: UserDataState = {
	user: null,
	loading: false,
	error: null
}

// User Store
const useUserStore = create<UserState>(set => ({
	...initialState,

	// Actions
	setLoading: (loading: boolean) =>
		set({
			loading,
			error: null
		}),
	setApiError: (error: string) =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),

	// Async actions
	authenticateUser: async (credentials: LoginCredentials) => {
		// Set loading to true
		set({ loading: true })

		try {
			// Authenticate user
			const { data } = await apiPublic.post('/auth/login', {
				email: credentials?.email,
				password: credentials?.password,
				captchaToken: credentials?.captchaToken
			})

			// Axios data response
			const userResponse = data as ApiResponse<UserResponse>

			if (userResponse.status === 'success' && userResponse.data) {
				// Save token to local storage
				setLocalStorage(commonSettings.apps.api.tokenName, userResponse.data.token)

				// Save user to state
				set({ user: userResponse.data.user, loading: false, error: null })

				return true
			}

			set({ loading: false, error: userResponse.message })
			return false
		} catch (error) {
			const errorMsg = apiErrorHandler(error)
			set({ loading: false, error: errorMsg })
			return false
		}
	},

	updateUserLanguage: (isoCode: string) => {
		set(state => ({
			...state,
			user: state.user ? { ...state.user, language: isoCode } : null
		}))
	},

	authenticateUserWithToken: async () => {
		// Set loading to true
		set({ loading: true })

		// Check if token exists
		const token = getLocalStorage(commonSettings.apps.api.tokenName)

		if (token) {
			try {
				// Get user with token
				const { data } = await apiProtected.get('/user')

				// Axios data response
				const userResponse = data as ApiResponse<User>

				// Save user to state
				if (userResponse.status === 'success' && userResponse.data) {
					set({ user: userResponse.data, loading: false, error: null })
				} else {
					set({ loading: false, error: userResponse.message })
				}
			} catch (error) {
				const errorMsg = apiErrorHandler(error)
				set({ loading: false, error: errorMsg })

				// Logout user
				useUserStore.getState().logout()
			}
		} else {
			set({ loading: false })
		}
	},

	logout: () => {
		// Remove token from local storage
		setLocalStorage(commonSettings.apps.api.tokenName, '')

		// Reset user state
		useUserStore.getState().reset()

		// Navigate user to login page
		window.location.href = commonSettings.apps.web.paths.login
	}
}))

export default useUserStore
