import { useEffect, useState } from 'react'
import './EditPageTranslations.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import InfoBox from '../../../ruya-shared/shared/ui/atoms/infoBox/InfoBox'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import Tooltip from '../../../ruya-shared/shared/ui/atoms/tooltip/Tooltip'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import ProgressBar from '../../../ruya-shared/shared/ui/atoms/progressBar/ProgressBar'
import { FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'

// Molecules
import AdminPageNavigation from '../../molecules/adminPageNavigation/AdminPageNavigation'

// Zustand
import useLanguageStore from '../../../store/languageStore'

// App Settings
import appSettings from '../../../appSettings'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Toast
import { toast } from 'react-toastify'

// Shared
import type { ILanguage, IPage, IPageLanguageRelation } from '../../../ruya-shared/shared/types'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const EditPageTranslations = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [content, setContent] = useState<IPage | null>(null)
	const [translationButtonsDisable, setTranslationButtonsDisable] = useState<boolean>(false)
	const [isLegalContent, setIsLegalContent] = useState<boolean>(false)
	const [isLongContent, setIsLongContent] = useState<boolean>(false)
	const [isTranslateAllLoading, setIsTranslateAllLoading] = useState<boolean>(false)
	const [selectedModel, setSelectedModel] = useState<string | undefined>(
		appSettings.ai.languageModels.find(model => model.isDefault)?.modelName
	)

	// Translation status
	const [bulkOperation, setBulkOperation] = useState<IBulkAIOperation | null>(null)

	useEffect(() => {
		checkBulkOperationStatus('translation')
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			if (bulkOperation?.status === 'in-progress') {
				checkBulkOperationStatus('translation')
				getContent()
			}
		}, 5000)
		return () => clearInterval(interval)
	}, [bulkOperation])

	const checkBulkOperationStatus = async (operationType: string) => {
		setIsTranslateAllLoading(true)
		try {
			const { data } = await apiProtected.get(`admin/ai/bulk-operation-status/${operationType}`)

			if (data?.status === 'success' && data?.data) {
				setBulkOperation(data.data.operation)
			} else {
				setBulkOperation(null)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		} finally {
			setIsTranslateAllLoading(false)
		}
	}

	const stopBulkOperation = async (operationType: string, forceStop: boolean = false) => {
		try {
			const { data } = await apiProtected.get(`admin/ai/stop-bulk-operation/${operationType}/${forceStop}`)

			if (data?.status === 'success' && data?.data) {
				setBulkOperation(data.data.operation)
			} else {
				setBulkOperation(null)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		}
	}

	// Get Content by ID with api call
	useEffect(() => {
		if (!id) navigate('/page')
		getContent()
	}, [id])

	// Get Content by ID
	const getContent = async () => {
		try {
			const response = await apiProtected.get(`admin/page/${id}`)

			if (response.data.status === 'success') {
				const contentData = response.data.data as IPage
				// Update state
				setContent(contentData)
			} else {
				setApiError(response.data.message)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		}
	}

	// Handle translation click
	const handleTranslateAll = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:translateAll'),
			buttons: [
				{
					label: t('button:translateAll'),
					onClick: () => translateAll(true),
					className: 'button--danger'
				},
				{
					label: t('button:translateOnlyMissing'),
					onClick: () => translateAll(false),
					className: 'button--success'
				},
				{
					label: t('button:cancel'),
					onClick: () => false
				}
			]
		})
	}

	// Handle stop bulk operation click
	const handleStopBulkOperation = async (operationType: string) => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:stopBulkOperation'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => stopBulkOperation(operationType),
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => false
				},
				{
					label: t('button:forceStop'),
					onClick: () => stopBulkOperation(operationType, true),
					className: 'button--danger'
				}
			]
		})
	}

	const translateAll = async (overwriteAll: boolean) => {
		setIsTranslateAllLoading(true)
		try {
			const response = await apiProtected.post('admin/ai/translate-content-all', {
				sourcePageId: content?._id,
				model: appSettings.ai.languageModels.find(model => model.isDefault)?.modelName,
				overwriteAll,
				isLegalContent,
				isLongContent
			})
			if (response?.data?.status === 'success') {
				checkBulkOperationStatus('translation')
			} else {
				setApiError(response.data.message)
			}
		} catch (error) {
			setApiError(apiErrorHandler(error))
		} finally {
			setIsTranslateAllLoading(false)
		}
	}

	return (
		<main className="EditPageTranslations">
			<h1 className="Admin_H1">{t('admin:headerEditPage')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${content?.lang}/pages/${content?.slug}`} />

			<AdminPageNavigation id={id} lang={content?.lang} />
			<ProgressBar
				total={languageStore.allLanguages.filter(lang => lang.active === true).length - 1}
				completed={content?.languageRelations?.length || 0}
			/>

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<FormGroup>
				<FormFieldSet className="EditPageTranslations_TranslationSettings" legend={t('admin:translationSettings')}>
					<Switch
						name="isLegalContent"
						label={t('form:isLegalContent.label')}
						value={isLegalContent}
						onChange={e => setIsLegalContent(e.target.value as unknown as boolean)}
					/>
					<Switch
						name="isLongContent"
						label={t('form:isLongContent.label')}
						value={isLongContent}
						onChange={e => setIsLongContent(e.target.value as unknown as boolean)}
					/>
					<Select
						inputSize="sm"
						label={t('form:model.label')}
						keyField="modelName"
						valueField="modelName"
						labelField="modelName"
						value={selectedModel}
						options={appSettings.ai.languageModels}
						onChange={(e: any) => setSelectedModel(e.target.value)}
					/>
				</FormFieldSet>
			</FormGroup>

			<FormGroup>
				<FormFieldSet className="EditPageTranslations_WarningZone" legend={t('admin:bulkHeader')}>
					{bulkOperation?.status === 'in-progress' ? (
						<InfoBox icon="true" type="warning" text={t('admin:translationInProgress')}>
							<div className="EditPageTranslations_WarningZone_Buttons">
								<Button icon="sync" color="success" onClick={() => checkBulkOperationStatus('translation')} />
								<Button
									icon="cancel"
									text={t('button:stopBulkTranslation')}
									loading={bulkOperation.stopRequested}
									loadingText={t('button:stopBulkTranslationRequested')}
									color="error"
									onClick={() => handleStopBulkOperation('translation')}
								/>
							</div>
							<div className="EditPageTranslations_Status">
								<div>
									<strong>{t('admin:section')}:</strong> {bulkOperation?.section}
								</div>
								<div>
									<strong>{t('admin:completed')}:</strong> {bulkOperation?.completed} of {bulkOperation?.total}
								</div>
								<div>
									<strong>{t('admin:translatingLanguage')}:</strong> {bulkOperation?.targetLanguage}
								</div>
								<div>
									<strong>{t('admin:completedChunks')}:</strong> {bulkOperation?.completedChunks} of{' '}
									{bulkOperation?.totalChunks}
								</div>
								<div>
									<strong>{t('admin:failed')}:</strong> {bulkOperation?.failed}
								</div>
								<div>
									{bulkOperation?.errorList.map((error: string, index: number) => (
										<div key={index}>{error}</div>
									))}
								</div>
							</div>
						</InfoBox>
					) : (
						<Button
						text={t('button:translateAll')}
							icon="translate"
							color="warning"
							loading={isTranslateAllLoading}
							disabled={isTranslateAllLoading}
							onClick={handleTranslateAll} />
					)}
				</FormFieldSet>
			</FormGroup>

			{content?.lang === languageSettings.defaultLanguage && (
				<table className="EditPageTranslations_LanguageTable">
					<thead>
						<tr>
							<th />
							<th>{t('admin:columnISO')}</th>
							<th>{t('admin:columnLanguage')}</th>
							<th>{t('admin:columnActions')}</th>
							<th>{t('admin:columnStatus')}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{languageStore.allLanguages.map((language: ILanguage) => {
							if (language.isoCode !== languageSettings.defaultLanguage && language.active === true)
								return (
									<TranslationItem
										key={language.isoCode}
										language={language}
										content={content}
										setContent={setContent}
										translationButtonsDisable={translationButtonsDisable || bulkOperation?.status === 'in-progress'}
										setTranslationButtonsDisable={setTranslationButtonsDisable}
										isLegalContent={isLegalContent}
										isLongContent={isLongContent}
										selectedModel={selectedModel}
									/>
								)
						})}
					</tbody>
				</table>
			)}
		</main>
	)
}

interface TranslationItemProps {
	language: ILanguage
	content: IPage | null
	setContent: React.Dispatch<React.SetStateAction<IPage | null>>
	translationButtonsDisable: boolean
	setTranslationButtonsDisable: React.Dispatch<React.SetStateAction<boolean>>
	isLegalContent: boolean
	isLongContent: boolean
	selectedModel: string | undefined
}

const TranslationItem = (props: TranslationItemProps) => {
	// Destruct props
	const {
		language,
		content,
		setContent,
		translationButtonsDisable,
		setTranslationButtonsDisable,
		isLegalContent,
		isLongContent,
		selectedModel
	} = props

	// Translation Hook
	const { t } = useTranslation()

	// State
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [translatedContent, setTranslatedContent] = useState<IPageLanguageRelation | null>(null)
	const [translationError, setTranslationError] = useState<string | null>(null)

	// Onload check if content translation exists
	useEffect(() => {
		const result = content?.languageRelations?.find((relation: IPageLanguageRelation) => relation.lang === language.isoCode)
		if (result) {
			setTranslatedContent(result)
		}
	}, [content?.languageRelations])

	// Handle translation click
	const handleTranslation = async () => {
		if (translatedContent) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:translateContentAgain'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => translateContent(),
						className: 'button--danger'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		} else {
			translateContent()
		}
	}

	// Handle clone click
	const handleClone = async () => {
		if (translatedContent) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:overwritePage'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => cloneContent(),
						className: 'button--danger'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		} else {
			cloneContent()
		}
	}

	// Handle delete click
	const handleDelete = async () => {
		if (translatedContent) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:deleteTranslation'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => deleteContent(),
						className: 'button--danger'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		}
	}

	// Translate content
	const translateContent = async () => {
		// Disable translation
		setTranslationButtonsDisable(true)

		// Enable translation after 30 seconds
		setTimeout(() => setTranslationButtonsDisable(false), 5000)

		// Reset error
		setTranslationError(null)

		// Set loading
		setIsLoading(true)

		try {
			const response = await apiProtected.post('admin/ai/translate-content', {
				sourcePageId: content?._id,
				targetPageId: translatedContent?.relatedPageId || null,
				targetLanguage: language.isoCode,
				model: selectedModel,
				isLegalContent,
				isLongContent
			})

			if (response?.data?.status === 'success') {
				const updatedRelation = response.data.data as IPage
				// Update parent state
				setContent(prevContent => {
					if (!prevContent) return null

					// Return a completely new object
					return {
						...prevContent,
						languageRelations: updatedRelation.languageRelations
					}
				})
			} else {
				setTranslationError(response.data.message)
			}
		} catch (error) {
			setTranslationError(apiErrorHandler(error))
		}

		setIsLoading(false)
	}

	// Clone content
	const cloneContent = async () => {
		// Reset error
		setTranslationError(null)

		try {
			const response = await apiProtected.post('admin/page/clone', {
				sourcePageId: content?._id,
				targetPageId: translatedContent?.relatedPageId || null,
				targetLanguage: language.isoCode
			})

			if (response?.data?.status === 'success') {
				const updatedRelation = response.data.data as IPage
				// Update parent state
				setContent(prevContent => {
					if (!prevContent) return null

					// Return a completely new object
					return {
						...prevContent,
						languageRelations: updatedRelation.languageRelations
					}
				})
			} else {
				setTranslationError(response.data.message)
			}
		} catch (error) {
			setTranslationError(apiErrorHandler(error))
		}
	}

	// Delete content
	const deleteContent = async () => {
		try {
			const response = await apiProtected.delete(`admin/page/${translatedContent?.relatedPageId}`)

			if (response?.data?.status === 'success') {
				// Show success message
				toast.success(t('info:translationDeleted'), {
					position: 'bottom-center',
					autoClose: 2000
				})

				setTranslatedContent(null)
			} else {
				// Show error message
				toast.error(response.data.message || t('error:unexpectedError'), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		} catch (error) {
			// Show error message
			toast.error(apiErrorHandler(error) || t('error:unexpectedError'), {
				position: 'bottom-center',
				autoClose: 2000
			})
		}
	}

	return (
		<tr
			key={language.isoCode}
			className={`EditPageTranslations_LanguageTable${
				translatedContent ? ' EditPageTranslations_LanguageTable--translated' : ''
			}${translationError ? ' EditPageTranslations_LanguageTable--error' : ''}`}>
			<td>
				<Tooltip content={language.englishName}>
					<span
						className="EditPageTranslations_Flag"
						style={{ backgroundImage: `url(${commonSettings.apps.cdn.url}/assets/flags/4x3/${language.flag})` }}
					/>
				</Tooltip>
			</td>
			<td>
				<Tooltip content={language.englishName}>
					<span>{language.isoCode}</span>
				</Tooltip>
			</td>
			<td className="EditPageTranslations_Language">
				{translatedContent ? (
					<ReactLink href={`/page/edit/${translatedContent.relatedPageId}`}>{language.displayName}</ReactLink>
				) : (
					language.displayName
				)}
			</td>
			<td>
				<div className="EditPageTranslations_LanguageTable_Actions">
					{isLoading ? (
						<div>{t('admin:translating')}</div>
					) : (
						<>
							<Tooltip content={translatedContent ? t('admin:overwriteFromOriginal') : t('admin:cloneFromOriginal')}>
								<Button
									size="sm"
									icon="content_copy"
									color={translatedContent ? 'error' : 'success'}
									onClick={handleClone}
									disabled={isLoading}
								/>
							</Tooltip>
							{language.autoTranslate ? (
								<>
									<Button
										size="sm"
										color={translatedContent ? 'warning' : 'success'}
										onClick={handleTranslation}
										disabled={isLoading || translationButtonsDisable}
										text={translatedContent ? t('admin:translateAgainWith') : t('admin:translateWith')}
									/>
								</>
							) : (
								<div>Auto translation disabled.</div>
							)}
						</>
					)}
				</div>
			</td>
			<td className="EditPageTranslations_LanguageTable_Status">
				{translationError ? (
					<Tooltip content={translationError}>
						<MaterialSymbol name="error" />
					</Tooltip>
				) : (
					<MaterialSymbol name="translate" className={isLoading ? 'css-flip' : ''} />
				)}
			</td>
			<td className="EditPageTranslations_LanguageTable_Delete">
				{translatedContent && <MaterialSymbol name="delete_forever" onClick={handleDelete} />}
			</td>
		</tr>
	)
}

export default Admin(EditPageTranslations)
