import type { HTMLAttributes, ReactNode } from 'react'
import './InfoBox.scss'

interface InfoBoxProps extends HTMLAttributes<HTMLDivElement> {
	header?: string
	text?: string
	icon?: string
	className?: string
	children?: ReactNode
	type?: 'info' | 'success' | 'warning' | 'error'
}

const InfoBox = (props: InfoBoxProps) => {
	const { header, text, className, ...rest } = props

	// Classes
	const classNames = 'InfoBox' + `${className !== undefined ? ' ' + className : ''}`

	return (
		<div className={classNames} {...rest}>
			{header && <h4>{header}</h4>}
			{text && <p>{text}</p>}
			{props.children}
		</div>
	)
}

export default InfoBox
