import { useEffect, useState } from 'react'
import './Theories.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import InfoBox from '../../../ruya-shared/shared/ui/atoms/infoBox/InfoBox'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Zustand
import useInterpreterStore from '../../../store/interpreterStore'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

// Types
import type { IInterpreterSearchParams } from '../../../@types/interpreter'
import type { IInterpreter } from '../../../ruya-shared/shared/types'

const Theories = () => {
	// Router
	const navigate = useNavigate()

	// Post Search Params
	const initialIInterpreterSearchParams: IInterpreterSearchParams = {
		language: languageSettings.defaultLanguage,
		active: 'all'
	}

	// Search Params
	const [interpreterSearchParams, setInterpreterSearchParams] = useState<IInterpreterSearchParams>(initialIInterpreterSearchParams)

	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const interpreterStore = useInterpreterStore()

	// Get Posts
	useEffect(() => {
		interpreterStore.loadInterpreterList(interpreterSearchParams.language, interpreterSearchParams.active)
	}, [interpreterSearchParams])

	// Status filter change
	const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setInterpreterSearchParams({
			...interpreterSearchParams,
			active: e.target.value as IInterpreterSearchParams['active']
		})
	}

	// Language filter change
	const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setInterpreterSearchParams({
			...interpreterSearchParams,
			language: e.target.value as IInterpreterSearchParams['language']
		})
	}

	// Create Post
	const handleCreateTheory = () => {
		navigate('/theories/add')
	}

	// Loading
	if (interpreterStore.loading)
		return (
			<main className="Theories">
				<Loading />
			</main>
		)

	// Error
	if (interpreterStore.error)
		return (
			<main className="Theories">
				<InfoBox icon="true" type="error" text={interpreterStore.error} />
			</main>
		)

	return (
		<main className="Theories">
			<h1 className="Admin_H1">{t('admin:headerTheories')}</h1>

			<div className="Theories_Bar">
				<Button text={t('button:addTheory')} color="success" onClick={handleCreateTheory} />
			</div>

			<div className="Theories_List">
				<table className="Theories_List_Table">
					<thead>
						<tr className="Theories_List_Row">
							<th className="Theories_List_Title">{t('admin:theory')}</th>
							<th className="Theories_List_Status">
								<Select inputSize="xs" onChange={handleStatusChange} value={interpreterSearchParams.active}>
									<option value="all">{t('options:all')}</option>
									<option value="1">{t('options:active')}</option>
									<option value="0">{t('options:disabled')}</option>
								</Select>
							</th>
							<th className="Theories_List_Language">
								<Select
									inputSize="xs"
									onChange={handleLanguageChange}
									options={getActiveLanguages}
									labelField="displayName"
									valueField="isoCode"
									value={interpreterSearchParams.language}
								/>
							</th>
						</tr>
					</thead>
					<tbody className="Theories_List_Body">
						{interpreterStore.interpreterList.map((interpreter: IInterpreter, index) => {
							const key = interpreter?._id ? interpreter._id.toString() : index

							return (
								<tr
									className={`Theories_List_Body_Row${interpreter.active ? '' : ' Theories_List_Body_Row--disabled'}`}
									key={key}
									onClick={() => navigate(`/theories/edit/${interpreter._id}`)}>
									<th className="Theories_List_Title">{interpreter.name}</th>
									<th className="Theories_List_Status">{interpreter.active ? t('options:active') : t('options:disabled')}</th>
									<th className="Theories_List_Language">{interpreter.language}</th>
								</tr>
							)
						})}
					</tbody>
				</table>
				{!interpreterStore.interpreterList.length && <InfoBox icon="true" type="info" text={t('admin:noRecordsFound')} />}
			</div>
		</main>
	)
}

export default Admin(Theories)
