import { useState, useEffect } from 'react'
import './EmailSubscriberChart.scss'

// API
import apiProtected from '../../../api/apiProtected'

// Chart
import { ResponsiveLine } from '@nivo/line'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Types
interface SignupStats {
	x: string
	y: number
}

interface DateFilter {
	label: string
	value: Date
}

type ChartDataType = { id: string; data: SignupStats[] }[]

// Today's date
const dateNow = new Date()

const dateFilters: DateFilter[] = [
	{ label: '1y', value: new Date(new Date(dateNow).setFullYear(dateNow.getFullYear() - 1)) },
	{ label: '6m', value: new Date(new Date(dateNow).setMonth(dateNow.getMonth() - 6)) },
	{ label: '3m', value: new Date(new Date(dateNow).setMonth(dateNow.getMonth() - 3)) },
	{ label: '1m', value: new Date(new Date(dateNow).setMonth(dateNow.getMonth() - 1)) }
]

const customTheme = {
	axis: {
		ticks: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12
			}
		},
		legend: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12
			}
		}
	},
	labels: {
		text: {
			fontFamily: 'Figtree, sans-serif'
		}
	}
}

const EmailSubscriberChart = () => {
	// Local state
	const [loading, setLoading] = useState(true)
	const [chartData, setChartData] = useState<ChartDataType | null>(null)
	const [maxUser, setMaxUser] = useState<number>(0)

	// Date ranges
	const [startDate, setStartDate] = useState<string>(dateFilters[3].value.toISOString().slice(0, 10))
	const [endDate] = useState<string>(new Date(dateNow).toISOString().slice(0, 10))

	// Fetch data on mount
	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		if (!chartData?.[0]?.data) return

		// Assuming rawData is an array of { x: 'YYYY-MM-DD', y: count }
		const filledData = fillDataGaps(startDate, endDate, chartData[0].data)

		// Calculate the max user count
		const maxUserCount = Math.max(...filledData.map(d => d.y))

		// Update state
		setMaxUser(maxUserCount)
		setChartData([{ id: 'sign-up-dates', data: filledData }])
	}, [startDate, endDate])

	const fillDataGaps = (startDate: string, endDate: string, data: SignupStats[]): SignupStats[] => {
		const date = new Date(startDate)
		const endDateObj = new Date(endDate)
		const filledData: SignupStats[] = []
		// Define dataMap with an index signature
		const dataMap: { [key: string]: number } = data.reduce((acc: { [key: string]: number }, item) => {
			acc[item.x] = item.y
			return acc
		}, {})

		while (date <= endDateObj) {
			const dateString = date.toISOString().slice(0, 10)
			filledData.push({
				x: dateString,
				y: dataMap[dateString] || 0 // Now TypeScript knows that dataMap can be indexed with a string.
			})
			date.setDate(date.getDate() + 1)
		}

		return filledData
	}

	// Fetch data from API
	const fetchData = async () => {
		try {
			setLoading(true)
			const response = await apiProtected.get('/admin/statistics/email-subscriber-stats')
			const data: SignupStats[] = response.data.data

			// Calculate the max user count
			const maxUserCount = Math.max(...data.map(d => d.y))
			setMaxUser(maxUserCount)

			// Assuming rawData is an array of { x: 'YYYY-MM-DD', y: count }
			const filledData = fillDataGaps(startDate, endDate, data)

			setChartData([{ id: 'sign-up-dates', data: filledData }])
			setLoading(false)
		} catch (error) {
			setChartData([{ id: 'sign-up-dates', data: [] }])
			console.error('Error fetching chart data:', error)
			setLoading(false)
		}
	}

	const generateTickValues = (maxValue: number, maxTicks: number = 10) => {
		const step = Math.ceil(maxValue / maxTicks)
		return Array.from({ length: Math.ceil(maxValue / step) + 1 }, (_, i) => i * step)
	}

	// Check if a date is selected
	const isSelected = (date: Date) => startDate === date.toISOString().slice(0, 10)

	return (
		<div className="EmailSubscriberChart">
			<h4 className="EmailSubscriberChart_Header">Email subscribers by day</h4>
			<div className="EmailSubscriberChart_Filters">
				{dateFilters.map((filter, index) => (
					<div
						key={filter.value.toTimeString()+index}
						className={`EmailSubscriberChart_Filters_Filter ${
							isSelected(filter.value) ? 'EmailSubscriberChart_Filters_Filter--selected' : ''
						}`}
						onClick={() => setStartDate(filter.value.toISOString().slice(0, 10))}>
						{filter.label}
					</div>
				))}
			</div>
			<div className="EmailSubscriberChart_Body">
				{loading && <Loading />}
				{!loading && chartData && (
					<div className="EmailSubscriberChart_Body_Chart">
						<ResponsiveLine
							theme={customTheme}
							data={chartData}
							margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
							xScale={{
								type: 'time',
								format: '%Y-%m-%d',
								min: startDate,
								max: endDate,
								precision: 'day'
							}}
							xFormat="time:%Y-%m-%d"
							axisLeft={{
								tickValues: generateTickValues(maxUser),
								legend: 'New users',
								legendOffset: -40,
								legendPosition: 'middle'
							}}
							axisBottom={{
								format: '%b %d', // For example, 'Apr 01'
								tickValues: 'every month', // Adjust this to fit your data density
								legend: 'Date',
								legendOffset: 36,
								legendPosition: 'middle'
							}}
							colors={{ scheme: 'set3' }}
							pointSize={4}
							pointLabelYOffset={-12}
							enableTouchCrosshair={true}
							useMesh={true}
							enableSlices="y"
							defs={[
								{
									id: 'dots',
									type: 'patternDots',
									background: 'inherit',
									color: '#ff3974',
									size: 4,
									padding: 1,
									stagger: true
								}
							]}
							fill={[
								{
									match: {
										id: 'dots'
									},
									id: 'dots'
								}
							]}
							curve="monotoneX"
							// After this just for suppress the warnings
							role="img"
							crosshairType="cross"
							enableCrosshair={true}
							sliceTooltip={({ slice }) => {
								return (
									<div
										style={{
											background: 'white',
											padding: '9px',
											border: '1px solid #ff3974',
											borderRadius: '8px'
										}}>
										<div>Date: {slice.points[0].data.xFormatted}</div>
										<div>New user: {slice.points[0].data.yFormatted}</div>
									</div>
								)
							}}
							debugSlices={false}
							debugMesh={false}
							tooltip={() => false}
							isInteractive={true}
							legends={[]} // Hide legends
							lineWidth={1}
							areaBaselineValue={0}
							areaBlendMode="normal"
							areaOpacity={0.2}
							enableArea={true}
							enablePointLabel={true}
							pointLabel="n"
							pointBorderColor="#ff3974"
							pointBorderWidth={0}
							pointColor="#ff3974"
							enablePoints={true}
							enableGridY={true}
							enableGridX={false}
							layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
							yScale={{
								type: 'linear',
								stacked: false,
								min: 0,
								max: maxUser
							}}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default EmailSubscriberChart
