import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import './GenerateArticle.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Prompt
import { articlePrompt } from '../../../ai/prompts/article'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'
import InfoBox from '../../../ruya-shared/shared/ui/atoms/infoBox/InfoBox'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'

// Molecules
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// React Hook Form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

// API
import apiProtected from '../../../api/apiProtected'

// Validation
import { generateArticleSchema } from '../../../validation/articleSchema'

// Zustand
import useArticleStore from '../../../store/articleStore'

// App Settings
import appSettings from '../../../appSettings'

// Types
import type { GenerateArticleFormValues, GenerateArticleModalProps, GenerateArticleProps } from '../../../@types/generateArticle'

// Shared
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const GenerateArticle = (props: GenerateArticleProps) => {
	// Props
	const { onResponse, onClose } = props

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const tags = useArticleStore(state => state.tags)

	const tagString = tags.map(tag => `${tag.name} (${tag.slug})`).join(', ')

	// Get language object
	const language = getActiveLanguages.find(lang => lang.isoCode === languageSettings.defaultLanguage)

	// React Hook Form
	const defaultValues = {
		prompt: 'Please write an article about ...',
		template: articlePrompt(language?.isoCode + '', language?.englishName + '', tagString),
		model: appSettings.ai.languageModels.find(model => model.isDefault === true)?.modelName
	}
	const formOptions = { defaultValues, mode: 'onChange', resolver: yupResolver<GenerateArticleFormValues>(generateArticleSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<GenerateArticleFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: GenerateArticleFormValues) => {
		// Set loading
		setError(null)
		setLoading(true)

		try {
			const response = await apiProtected.post('/admin/ai/prompt', values)

			if (response.data.status === 'success') {
				onResponse(response.data.data)
				setError(null)
				setLoading(false)
			} else {
				setLoading(false)
				setError(response.data.message)
			}
		} catch (error) {
			setLoading(false)
			const errorMsg = apiErrorHandler(error)
			setError(errorMsg)
		}
	}

	return (
		<div className="GenerateArticle">
			<div className="GenerateArticle_Header">
				<h1>{t('admin:generateArticleHeader')}</h1>
				<MaterialSymbol name="close" onClick={() => onClose()} />
			</div>
			<div className="GenerateArticle_Body">
				<Form className="GenerateArticle_Form" noValidate>
					<FormGroup>
						<TextArea
							className="GenerateArticle_Template"
							label={t('form:templatePrompt.label')}
							placeholder={t('form:templatePrompt.placeholder')}
							resize="vertical"
							showTooltip={true}
							tooltipText={t('form:templatePrompt.helper')}
							{...register('template')}
						/>
					</FormGroup>

					<FormGroup>
						<Select
							label={t('form:model.label')}
							keyField="modelName"
							valueField="modelName"
							labelField="modelName"
							options={appSettings.ai.languageModels}
							{...register('model')}
						/>
					</FormGroup>

					<FormGroup>
						<TextArea
							className="GenerateArticle_Prompt"
							label={t('form:articlePrompt.label')}
							placeholder={t('form:articlePrompt.placeholder')}
							resize="vertical"
							showTooltip={true}
							tooltipText={t('form:articlePrompt.helper')}
							{...register('prompt')}
						/>
					</FormGroup>

					{error && (
						<FormGroup>
							<InfoBox icon="true" type="error" text={error || t('error:unexpectedError')} />
						</FormGroup>
					)}

					<FormGroup>
						<Button
							icon="article_shortcut"
							type="button"
							color="success"
							onClick={handleSubmit(onSubmit)}
							disabled={loading || !isValid}
							loading={loading}
							text={t('button:generateArticle')}
							loadingText={t('button:loading')}
							block />
					</FormGroup>
				</Form>
			</div>
		</div>
	)
}

export const GenerateArticleModal = (props: GenerateArticleModalProps) => {
	const { isOpen, onClose, onResponse } = props

	const modalRoot = document.createElement('modal-root')

	useEffect(() => {
		document.body.appendChild(modalRoot)
		return () => {
			document.body.removeChild(modalRoot)
		}
	}, [modalRoot])

	if (!isOpen) return null

	return createPortal(
		<div className="Admin_Modal">
			<div onClick={e => e.stopPropagation()} className="Admin_Modal_Content">
				<GenerateArticle onResponse={onResponse} onClose={onClose} />
			</div>
		</div>,
		modalRoot
	)
}

export default GenerateArticle
