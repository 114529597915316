import { type ReactNode, useState } from 'react'
import './ContentBox.scss'

// Atoms
import H2 from '../h2/H2'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface IContentBoxProps {
	icon?: string
	title: string
	className?: string
	children?: ReactNode
	isCollapsible?: boolean
	defaultCollapsed?: boolean
}

const ContentBox = (props: IContentBoxProps) => {
	const { icon, title, className, children, isCollapsible, defaultCollapsed = false } = props

	// State for collapsed content
	const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)

	// Classes
	const classNames =
		'ContentBox' +
		`${className ? ' ' + className : ''}` +
		`${icon ? ' ContentBox--hasIcon' : ''}` +
		`${isCollapsed ? ' ContentBox--collapsed' : ' ContentBox--open'}`

	return (
		<div className={classNames}>
			<div className="ContentBox_Header">
				{icon && <MaterialSymbol name={icon} />}
				<H2>{title}</H2>
				{isCollapsible && (
					<MaterialSymbol
						className="ContentBox_CollapseIcon"
						name={isCollapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
						onClick={() => setIsCollapsed(!isCollapsed)}
					/>
				)}
			</div>
			<div className="ContentBox_Content">{children}</div>
		</div>
	)
}

export default ContentBox
