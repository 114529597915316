import { useEffect, useState } from 'react'
import './Pages.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import InfoBox from '../../../ruya-shared/shared/ui/atoms/infoBox/InfoBox'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Zustand
import usePageStore from '../../../store/pageStore'

// Shared
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import type { IPage } from '../../../ruya-shared/shared/types'

const Pages = () => {
	// Router
	const navigate = useNavigate()

	// Content Search Params
	const initialContentSearchParams: IPageSearchParams = {
		lang: languageSettings.defaultLanguage,
		sort: 'desc'
	}

	// Search Params
	const [contentSearchParams, setContentSearchParams] = useState<IPageSearchParams>(initialContentSearchParams)

	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const pageStore = usePageStore()

	// Get Content List
	useEffect(() => {
		pageStore.getPageList(contentSearchParams)
	}, [contentSearchParams])

	// Language filter change
	const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setContentSearchParams({
			...contentSearchParams,
			lang: e.target.value as IPageSearchParams['lang']
		})
	}

	// Toggle sort
	const toggleSort = () => {
		setContentSearchParams({
			...contentSearchParams,
			sort: contentSearchParams.sort === 'asc' ? 'desc' : 'asc'
		})
	}

	// Create Page
	const handleCreateContent = () => {
		navigate('/page/add')
	}

	// Loading
	if (pageStore.loading)
		return (
			<main className="Pages">
				<Loading />
			</main>
		)

	// Error
	if (pageStore.error)
		return (
			<main className="Pages">
				<InfoBox icon="true" type="error" text={pageStore.error} />
			</main>
		)

	return (
		<main className="Pages">
			<h1 className="Admin_H1">{t('admin:headerPages')}</h1>

			<div className="Pages_Bar">
				<Button text={t('button:createPage')} color="success" onClick={handleCreateContent} />
			</div>

			<div className="Pages_List">
				<table className="Pages_List_Table">
					<thead>
						<tr className="Pages_List_Row">
							<th className="Pages_List_Title">{t('admin:articleTitle')}</th>
							<th className="Pages_List_Language">
								<Select
									inputSize="xs"
									onChange={handleLanguageChange}
									options={getActiveLanguages}
									labelField="displayName"
									valueField="isoCode"
									value={contentSearchParams.lang}
								/>
							</th>
						</tr>
					</thead>
					<tbody className="Pages_List_Body">
						{pageStore.pageList.map((page: IPage, index) => {
							// Use the _id if available, otherwise fallback to the index
							const key = page._id ? page._id.toString() : `page-${index}`

							return (
								<tr className="Pages_List_Body_Row" key={key} onClick={() => navigate(`/page/edit/${page._id}`)}>
									<th className="Pages_List_Title">{page.title}</th>
									<th className="Pages_List_Language">{page.lang}</th>
								</tr>
							)
						})}
					</tbody>
				</table>
				{!pageStore.pageList.length && <InfoBox icon="true" type="info" text={t('admin:noRecordsFound')} />}
			</div>
		</main>
	)
}

export default Admin(Pages)
